<template>
  <el-row class="table">
    <span class="title">学员标签管理</span>
    <!-- tagTreeData：{{tagTreeData}} -->
    <el-row class="table">
      <el-row class="row-bg">
        <el-button type="info" size="medium" @click="addTagGroup()" class="tb-button">新增学员标签组</el-button>
      </el-row>

      <!-- <el-tree :data="tagTreeData" :props="defaultProps"></el-tree> -->
      <el-tree
        :data="tagTreeData"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span class="tree-title">{{ data.title }}</span>
          <template v-if="!data.color">
            <el-button type="text" @click="editTag(data)">编 辑</el-button>
            <el-button type="text" @click="delTag(data)">删 除</el-button>
          </template>
          <template v-else>
            <span>
              <i class="el-icon-edit ico-edit" @click="editTag(data)"></i>
              <i class="el-icon-delete ico-dele" @click="delTag(data)"></i>
              <i class="el-icon-circle-plus" @click="addTag(data)"></i>
            </span>
          </template>
        </span>
      </el-tree>
    </el-row>

    <!-- <div class="tagList">
      <span class="tag-item" v-for="tag in tagList" :key="tag.id">
        {{tag.title}}
        <i class="el-icon-edit ico-edit" @click="edit(tag.id)"></i>
        <i class="el-icon-delete ico-dele" @click="dele(tag.id)"></i>
        <el-input
          class="input-new-tag"
          v-if="inputVisible === tag.id"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          v-focus
          @keyup.enter.native="$event.target.blur"
          @blur="handleInputConfirm(tag.id)"
        ></el-input>
      </span>
    </div> -->

    <!-- 新增标签组 -->
    <tag-group :pageFlag="studentTag" :show="groupDialog" @close="groupDialog=false;groupInfo=null" :groupInfo="groupInfo" @updateView="getTagGroupList"></tag-group>
    <!-- 新增标签 -->
    <add-tag :pageFlag="studentTag" :show="tagDialog" @close="tagDialog=false;tagInfo=null" :tagInfo="tagInfo" :groupId="groupId" @updateView="getTagGroupList"></add-tag>
  </el-row>
</template>

<script>
import tagGroup from '@/components/research/tag-group'
import addTag from '@/components/research/add-tag'
import { getTagGroupList, getTags, updateTags, delTagGroupList } from '@/api/research/tag'

export default {
  components: { tagGroup, addTag },
  data () {
    return {
      studentTag:'studentTag',
      inputVisible: false,
      groupDialog: false,
      tagDialog: false,
      tagInfo: null,
      groupInfo: null,
      inputValue: '',
      tagList: [], // 标签列表
      tagGroupList: [], // 标签组列表
      tagTreeData: [], // 标签树形结构数据
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      groupId: ''
    }
  },
  created () {
    this.getTagGroupList()
  },
  methods: {
    // 获取标签组
    getTagGroupList () {
      getTagGroupList({
        pageNum: 1,
        pageSize: 100,
        businessType:'用户'
      }).then(res => {
        if (res.state === 'success') {
          this.tagGroupList = res.body.list
          const tagTreeData = []
          this.tagGroupList.forEach((ele, idx) => {
            tagTreeData.push({
              ...ele
            })
          })
          getTags({
            pageNum: 1,
            pageSize: 10000,
            businessType:'用户'
          }).then(res => {
            if (res.state === 'success') {
              this.tagList = res.body
              tagTreeData.forEach((ele, idx) => {
                this.tagList.forEach((item, index) => {
                  if (item.groupId === ele.id) {
                    // ele.children = new
                    if (!ele.children) {
                      ele.children = []
                      ele.children.push({
                        ...item
                      })
                    } else {
                      ele.children.push({
                        ...item
                      })
                    }
                  }
                })
              })
              this.tagTreeData = tagTreeData
            }
          })
        }
      })
    },

    // 编辑标签
    edit (id) {
      this.inputValue = ''
      this.inputVisible = id
    },

    // 编辑标签
    editTag (data) {
      if (!data.color) {
        // 编辑普通标签
        this.tagInfo = data
        this.tagDialog = true
      } else {
        // 编辑标签组
        this.groupId = data.id
        this.groupInfo = data
        this.groupDialog = true
      }
    },

    // 删除标签
    delTag (data) {
      // console.log('data', data)
      if (!data.color) {
        // 删除普通标签
        this.$confirm('是否删除当前标签', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await updateTags({
              id: data.id,
              status: '已删除'
            })
            window.$msg('删除标签成功')
            this.getTagGroupList()
          })
          .catch(() => {
            return false
          })
      } else {
        // 删除标签组
        this.$confirm('是否删除当前标签组', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delTagGroupList({
              id: data.id
            })
            window.$msg('删除标签组成功')
            this.getTagGroupList()
          })
          .catch(() => {
            return false
          })
      }
    },

    // 删除标签
    dele (id) {
      this.$confirm('是否删除当前标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await updateTags({
            id,
            status: '已删除'
          })
          window.$msg('删除成功')
          this.getTags()
        })
        .catch(() => {
          return false
        })
    },

    // 编辑提交
    async handleInputConfirm (id) {
      this.inputVisible = false
      if (this.inputValue) {
        await updateTags({
          id,
          title: this.inputValue
        })
        this.getTags()
        window.$msg('编辑成功')
      }
    },

    // 新增标签组
    addTagGroup () {
      this.groupDialog = true
    },

    // 新增标签
    addTag (data) {
      this.groupId = data.id
      this.tagDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
// .tagList {
//   margin-top: 25px;
//   .tag-item {
//     display: inline-block;
//     padding: 10px 15px;
//     margin-bottom: 35px;
//     background: #ffffff;
//     border-radius: 20px 4px 20px 4px;
//     margin-right: 35px;
//     font-size: 14px;
//     color: #999999;
//   }
//
//   .input-new-tag {
//     display: block;
//     margin: 7px 0;
//     width: 250px !important;
//   }
// }
@import "../../style/table.scss";

.tree-title {
  display: inline-block;
  font-size: 14px;
  margin-right: 6px;
  margin-left: 3px;
}


i {
  cursor: pointer;
}
.ico-edit {
  margin-left: 7px;
  margin-right: 6px;
  color: #f5c319;
  &:hover {
    color: #72809c;
  }
}
.ico-dele {
  margin-right: 6px;
  &:hover {
    color: #f5c319;
  }
}
</style>
